.checkMark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--bliro-orange-5);
}

.divider {
  box-sizing: border-box;
  width: 338px;
  height: 1px;
  margin: 64px 0;
  background-color: var(--bliro-dark-6);
}
