.emailInput {
  padding: 8px 16px;
  font-size: 16px;
  transition: all 0.2s;
}

.required {
  color: var(--bliro-red-1);
}

input.required {
  border: 1px solid var(--bliro-red-1) !important;
}

input.required:focus {
  border: 1px solid var(--bliro-red-1) !important;
}

input.required:hover {
  border: 1px solid var(--bliro-red-2) !important;
}
