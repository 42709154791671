.container {
  width: 480px;
  height: 100%;
  background-color: var(--bliro-orange-6);
  background-image: url("./images/background.png");
  background-size: 100% 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-top: 200px;
}

.content {
  color: var(--Dark-Dark---2, #2b313c) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 38px !important; /* 158.333% */
  letter-spacing: -0.24px !important;

  max-width: 320px;
}

.highlight {
  color: var(--bliro-orange-1) !important;
}
