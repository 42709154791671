.container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 24px 0;
}

.content {
  width: 100%;
}

.contentBody {
  margin-top: 80px;
}

.testimonal {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .testimonal {
    display: block;
  }

  .content {
    width: 400px;
  }

  .wrapper {
    padding: 56px 0 0;
  }
}
