.input {
  font-size: 16px;
  border: none;
  transition: all 0.2s;
}

.input:focus {
  border: none;
  outline: none;
}

.iconBtn {
  cursor: pointer;
  transition: all 0.2s;
}

.iconBtn:hover {
  transform: scale(1.05);
}

div.required {
  border: 1px solid var(--bliro-red-1) !important;
}

div.required:focus-within {
  border: 1px solid var(--bliro-red-1) !important;
}

div.required:hover {
  border: 1px solid var(--bliro-red-2) !important;
}