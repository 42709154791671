.highlight {
  color: var(--bliro-orange-1) !important;
}

.container {
  margin-top: -32px;
}

.content {
  padding-top: 40px;
}

.signUpBtn {
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid var(--bliro-dark-7);
  background-color: white;
  transition: all 0.2s;
  cursor: pointer;
}

.signUpBtn:hover {
  background-color: var(--bliro-dark-8);
  border: 1px solid var(--bliro-dark-7);
  box-shadow: 0px 1px 1px 0px rgba(19, 26, 38, 0.08);
}

.logo {
  width: 28px;
  height: 28px;
}

.appleLogo {
  width: 23px;
  height: 28px;
}

.link {
  color: var(--bliro-dark-5)
}

.link:hover {
  color: var(--bliro-dark-4)
}

.link:visited {
  color: var(--bliro-dark-5)
}

.orContainer {
  position: relative;
  margin: 8px 0;
}

.or {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0px 16px;
  border-radius: 4px;
}

.nameInputWrapper {
  box-sizing: border-box;
  width: 50%;
}

.nameInputWrapper:first-child {
  padding-right: 4px;
}

.nameInputWrapper:last-child {
  padding-left: 4px;
}

.signUpBtnText {
  text-transform: capitalize;
}