:root {
  --black: #000;
  --white: #fff;

  --bliro-orange-1: #f26835;
  --bliro-orange-2: #f9855b;
  --bliro-orange-3: #faa484;
  --bliro-orange-4: #fcc2ad;
  --bliro-orange-5: #fde1d6;
  --bliro-orange-6: #fef0ea;
  --bliro-orange-dark: #a44725;

  --bliro-dark-1: #131a26;
  --bliro-dark-2: #2b313c;
  --bliro-dark-3: #424852;
  --bliro-dark-4: #71767d;
  --bliro-dark-5: #a1a3a8;
  --bliro-dark-6: #d0d1d4;
  --bliro-dark-7: #e7e8e9;
  --bliro-dark-8: #eff0f0;

  --bliro-red-1: #f43641;
  --bliro-red-2: #f65b64;
  --bliro-red-3: #f9848a;
  --bliro-red-4: #fbadb1;
  --bliro-red-5: #fdd6d8;
  --bliro-red-6: #feeaec;
  --bliro-red-dark: #a5252e;

  --bliro-green-1: #00bc8f;
  --bliro-green-2: #33caa6;
  --bliro-green-3: #66d7bc;
  --bliro-green-4: #80dec7;
  --bliro-green-5: #ccf2e9;
  --bliro-green-6: #e5f8f4;
  --bliro-green-dark: #008164;

  --bliro-blue-1: #1a60e7;
  --bliro-blue-2: #4880ec;
  --bliro-blue-3: #76a0f1;
  --bliro-blue-4: #a3bff5;
  --bliro-blue-5: #d1dffa;
  --bliro-blue-6: #e8effd;
  --bliro-blue-dark: #1242a2;

  --bliro-yellow-1: #f49f21;
  --bliro-yellow-2: #f6b24d;
  --bliro-yellow-3: #f8c57a;
  --bliro-yellow-4: #f9cf90;
  --bliro-yellow-5: #fdecd3;
  --bliro-yellow-6: #fef5e9;
  --bliro-yellow-dark: #a56d17;
}

@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url(./fonts/Inter.ttf) format("truetype");
}

* {
  font-family: "Inter" !important;
}

.MuiTooltip-tooltip {
  background-color: var(--bliro-dark-1) !important;
  margin-top: 4px !important;
  padding: 8px !important;
}

/* width */
::-webkit-scrollbar {
  display: unset !important;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
  margin: 4px 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bliro-dark-7);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bliro-dark-6);
}

/* width */
.notepad::-webkit-scrollbar {
  display: unset;
  width: 4px;
}

.notepad::-webkit-scrollbar-track {
  background: #f4dead;
  border-radius: 10px;
  margin: 4px 0;
}

/* Handle */
.notepad::-webkit-scrollbar-thumb {
  background: #00000020;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
.notepad::-webkit-scrollbar-thumb:hover {
  background: #00000050;
}

/* typography */
.text-h0 {
  font-size: 56px !important;
  font-weight: 700 !important;
  line-height: 72px !important;
  letter-spacing: -1.12px !important;
}

.text-h1 {
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 64px !important;
  letter-spacing: -0.96px !important;
}

.text-h2 {
  font-size: 40px !important;
  font-weight: 600 !important;
  line-height: 56px !important;
  letter-spacing: -0.8px !important;
}

.text-h3 {
  font-size: 36px !important;
  font-weight: 600 !important;
  line-height: 48px !important;
  letter-spacing: -0.72px !important;
}

.text-h4 {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  letter-spacing: -0.64px !important;
}

.text-subtitle0 {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  letter-spacing: -0.48px !important;
  color: var(--bliro-dark-5); /* Adjust according to your color variable setup */
  text-transform: uppercase;
}

.text-subtitle1 {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  letter-spacing: -0.48px !important;
}

.text-subtitle2 {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: -0.4px !important;
}

.text-subtitle3 {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: -0.16px !important;
}

.text-subtitle4 {
  font-size: 13px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  letter-spacing: -0.13px !important;
}

.text-xxSmallTitle {
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: -0.11px !important;
}

.text-xSmallTitle {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: -0.13px !important;
}

.text-smallTitle {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: -0.28px !important;
}

.text-normalTitle {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.32px !important;
}

.text-largeTitle {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  letter-spacing: -0.36px !important;
}

.text-xxSmallBody {
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: -0.11px !important;
  color: var(--bliro-dark-4); /* Adjust according to your color variable setup */
}

.text-xSmallBody {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: -0.13px !important;
  color: var(--bliro-dark-4); /* Adjust according to your color variable setup */
}

.text-smallBody-20 {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: -0.28px !important;
  color: var(--bliro-dark-4); /* Adjust according to your color variable setup */
}

.text-smallBody {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: -0.28px !important;
  color: var(--bliro-dark-4); /* Adjust according to your color variable setup */
}

.text-normalBody {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.32px !important;
  color: var(--bliro-dark-4); /* Adjust according to your color variable setup */
}

.text-largeBody {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  letter-spacing: -0.36px !important;
  color: var(--bliro-dark-4); /* Adjust according to your color variable setup */
}

.font-regular {
  font-weight: 400 !important;
}
s .font-medium {
  font-weight: 500 !important;
}

.font-semiBold {
  font-weight: 600 !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media,
  _:future,
  :root .font-semiBold {
    font-weight: 500 !important;
  }
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media,
  _:future,
  :root .font-bold {
    font-weight: 500 !important;
  }
}

.font-extraBold {
  font-weight: 800 !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media,
  _:future,
  :root .font-extraBold {
    font-weight: 500 !important;
  }
}

.platform-input {
  padding: 10px 16px !important;
  gap: 8px !important;
  border-radius: 4px !important;
  border: 1px solid var(--bliro-dark-6) !important;
  outline: none !important;

  color: var(--bliro-dark-2) !important;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.platform-input:hover {
  border: 1px solid var(--bliro-dark-5) !important;
}

.platform-input:focus {
  border: 1px solid var(--bliro-dark-3) !important;
}

.platform-input:focus-within {
  border: 1px solid var(--bliro-dark-3) !important;
}

.platform-datefield input {
  color: var(--bliro-dark-2) !important;
}

.platform-datefield fieldset {
  padding: 10px 16px !important;
  gap: 8px !important;
  border-radius: 4px !important;
  outline: none !important;
  border: 1px solid var(--bliro-dark-6) !important;
}

.platform-datefield:hover fieldset {
  border: 1px solid var(--bliro-dark-5) !important;
}

.platform-datefield:focus fieldset {
  border: 1px solid var(--bliro-dark-4) !important;
}

.platform-datefield input {
  color: var(--Dark-Dark---2, #2b313c) !important;
  /* Body/XSmall/Regular */
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important; /* 138.462% */
  letter-spacing: -0.13px !important;
  padding: 10px 0px 10px 16px !important;
}
