.container {
  margin-left: -2px;
  margin-right: -2px;
}

.wrapper {
  outline: 2px solid var(--bliro-orange-1);
  border: 2px solid transparent;
  border-radius: 4px;
}

.content {
  text-align: center;
}

